$('#expand').on('click', function () {
    let el = $('.table-responsive'),
        curHeight = el.height(),
        autoHeight = el.css('height', 'auto').height();
    el.height(curHeight).animate({
        height: autoHeight
    }, 50);
    // // $('.table-responsive').addClass('active');
    $('#expand').addClass('d-none');
    $('.hide').removeClass('d-none');
})
$('.hide').on('click', function () {
    $('#expand').removeClass('d-none');
    $('.table-responsive').animate({
        height: 320
    }, 50);
    // $('.table-responsive').removeClass('active');
    $('.hide').addClass('d-none');
})
$(".filters, .filter-block").niceScroll({
    cursorwidth: "3px",
    autohidemode: false,
    horizrailenabled: false
});



var lastScrollTop = 0;

$(window).scroll(function () {

    var scrollTop = $(this).scrollTop();

    if (scrollTop > lastScrollTop) {

        $('#navbar').removeClass('move-down');
        $('#nav').removeClass('move-down');
        $('.lang-box').removeClass('active');
        $('#navbarSupportedContent').removeClass('show');
    } else {
        $('#navbar').removeClass('move-down');
        $('#nav').removeClass('move-down');
        $('.lang-box').removeClass('active');
        $('#navbarSupportedContent').removeClass('show');
    }

    lastScrollTop = scrollTop;
});



$('.flexslider').flexslider({
    animation: "fade",
    touch: true,
    useCSS: false,
    controlNav: true,
    directionNav: false
});
$('.flexslider .slide-prev').click(function (event) {
    event.preventDefault();
    $(event.target).closest('.flexslider').flexslider("prev")
});
$('.flexslider .slide-next').click(function (event) {
    event.preventDefault();
    $(event.target).closest('.flexslider').flexslider("next")
});
$('.slick-slider').slick({
    fade: true,
    prevArrow: $('.prev-slide'),
    nextArrow: $('.next-slide'),
});


$('#product-slick1').slick({
    dots: false,
    infinite: true,
    speed: 300,
    variableWidth: true,
    // centerMode: true,
});
$('#product-slick2').slick({
    dots: false,
    infinite: true,
    speed: 300,
    variableWidth: true,
    // centerMode: true,
});
$('#product-slick3').slick({
    dots: false,
    infinite: true,
    speed: 600,
    variableWidth: true
    //centerMode: true,
});
$('#home-slick').slick({
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    asNavFor: '#home-slick-desc',
    responsive: [{
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,

            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,

            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 450,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
    ]
    //centerMode: true,
});
$('#home-slick-desc').slick({
    dots: false,
    fade: true,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
});
// // import Swiper JS
// import Swiper from 'swiper';
// // import Swiper styles
// import 'swiper/css';

//  var swiper = new Swiper(".mySwiper", {});

var swiper = new Swiper(".mySwiper", {
    loop: true,
    autoplay: {
        enabled: true,
        delay: 2500,
        disableOnInteraction: false
    },
    // pagination: {
    //     el: ".swiper-pagination",
    //     type: "fraction",
    // },
    // navigation: {
    //     nextEl: ".swiper-button-next",
    //     prevEl: ".swiper-button-prev",
    // },
    breakpoints: {
        450: {
            slidesPerView: 2,
            spaceBetween: 20,
            autoplay: {
                enabled: true
            }
        },
        767: {
            slidesPerView: 4,
            spaceBetween: 40
        },
        992: {

            slidesPerView: 5,
            spaceBetween: 50,
            autoplay: {
                enabled: false
            }

        },
    },
});
var visible = 0;

new WOW().init();
var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
})
$(window).on('scroll',function(){
    if($('#counters').length>0){
        if(!visible && isScrolledIntoView('#counters')){
            const counters = document.querySelectorAll('.counter');
            // Main function
            for(let n of counters) {
            const updateCount = () => {
                const target = + n.getAttribute('data-target');
                const count = + n.innerText;
                const speed = 17000; // change animation speed here
                const inc = target / speed; 
                if(count < target) {
                n.innerText = Math.ceil(count + inc);
                setTimeout(updateCount, 25);
                } else {
                n.innerText = target;
                }
            }
            updateCount();
            }
            visible = 1;
        }
    }
});
$('.modal').each(function() {
   var id =  $( this ).prop( "id" );
   var myModalEl = document.getElementById(id);
    myModalEl.addEventListener('shown.bs.modal', function (event) {
        $('#'+id+' .progress .progress-bar').css("width",function() {
            return $(this).attr("aria-valuenow") + "%";
        })
    })
    myModalEl.addEventListener('hidden.bs.modal', function (event) {
        $('.progress .progress-bar').css("width", "0%");
    })
});

function isScrolledIntoView(elem)
{
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();

    return ((elemTop <= docViewBottom) && (elemTop >= docViewTop));
}